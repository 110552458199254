import Head from 'next/head'
import { useTranslation } from 'next-i18next'

function MetaData() {
  const { t } = useTranslation()

  return (
    <Head>
      <title>{t('General.MetaData.General.Title')}</title>
      <meta name="description" content={t('General.MetaData.General.Description')} key="desc" />
      <meta property="og:title" name="og:title" content={t('General.MetaData.General.Title')} />
      <meta property="og:description" name="og:description" content={t('General.MetaData.General.Description')} />
      <meta property="og:image" name="og:image" content="https://onthatass.com/images/design/heroes/campaign/heroes-men-originals-mob.webp" />
    </Head>
  )
}

export default MetaData
